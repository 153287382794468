<template>
  <div class="bg-gray">
    <div v-if="!isLoading">
        <div class="container text-center mt-5 pt-3">
            <h5 class="sub-title">Interesują Cię</h5>
        </div>
        <div class="wrapper growing-cover-slider">
          <div class="swiper-wrapper">
            <OnBoardingSelectGenres :props="genres" id='new' :parent="addParent" />
          </div>
        </div>
            <h4 class="slider-book-title">{{label}}</h4>
            <div class="container tags-box mb-3">
              <ul>
                <li v-for="(tag, key) in bookData" :key="key"
                   @click="deleteCat(tag.id, key)"
                   :class="{active: genresInPlan.filter(function(item) { return item === tag.id}).length > 0}"
                  >
                 <span
                  :class="{dark: genresInPlan.filter(function(item) { return item === tag.id}).length > 0}">{{ tag.label }}
                    <i class="fas fa-times"></i>
                  </span>
                </li>
              </ul>
                <button class="btn-black-full w-100 mt-3 mb-5" @click="submit">Zapisz</button>
            </div>
    </div>
    <div v-else>
      <Loader />
    </div>
      <ModalSlot title="Dodaj książkę" v-if="isPopupShown === true" @close-modal="closeModal">
          <div class="text-center mb-4 modal-main-title">Musisz wybrać co najmiej dwa gatunki</div>
          <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
      </ModalSlot>
  </div>
</template>
<script>
import OnBoardingSelectGenres from '../components/OnBoardingSelectGenres'
import Loader from '../components/Loader'
import EventBus from '../eventBus'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'LibraryUpdateGenres',
  components: {
    OnBoardingSelectGenres,
    Loader,
    ModalSlot
  },
  data () {
    return {
      activeTab: 'books',
      isLoading: true,
      tabs: ['books'],
      bookData: {},
      list: [],
      label: '',
      genres: [],
      genresInPlan: [],
      parent: 0,
      addParent: 0,
      subcats: [],
      isPopupShown: false
    }
  },
  created () {
    this.$https('/genres?sorted=1', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      this.genres = response.data.response
      this.isLoading = false
    })
      .catch(error => {
        console.log('', error)
      })

    EventBus.$on('selected-books-genres', (payload) => {
      this.updateData(payload)
    })
    EventBus.$on('selected-genres', (payload) => {
      this.selectedGenres(payload)
    })
  },
  computed: {
    name: {
      get () {
        return this.$store.state.userData.name
      }
    }
  },
  watch: {
    genresInPlan: function () {
      // console.log(this.genresInPlan)
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      const selected = this.genresInPlan.join(',')
      if (this.genresInPlan.length < 2) {
        this.isLoading = false
        this.isPopupShown = true
      } else {
        const getData = `genres=${selected}`
        this.$https('/genres', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.isLoading = false
          this.$router.push('/')
        })
          .catch(error => {
            console.log('', error)
          })
      }
    },
    selectedGenres (payload) {
      this.genresInPlan = payload.map(e => e.id)
    },
    updateData (payload) {
      this.bookData = payload.categories
      this.label = payload.label
      this.parent = payload.parent
    },
    deleteCat (tag, key) {
      const index = this.genresInPlan.indexOf(tag)
      if (index > -1) {
        this.genresInPlan.splice(index, 1)
      } else {
        this.genresInPlan.push(tag)
        if (this.genresInPlan.indexOf(this.parent) === -1) {
          this.genresInPlan.push(this.parent)
          this.addParent = {
            id: this.parent,
            children: {
              id: tag
            },
            select: true
          }
        }
      }
    },
    closeModal () {
      this.isPopupShown = false
    }
  },
  beforeDestroy () {
    EventBus.$off('selected-books-genres', (payload) => {
      this.updateData(payload)
    })
    EventBus.$off('selected-genres', (payload) => {
      this.selectedGenres(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    margin-top: 25px;
  }
  .sub-title{
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
  }
  .tags-box {
  position: relative;
  margin-top: 15px;
  height: 100%;

  ul {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      padding: 6px 7px;
      transition: border-color .2s ease, color .2s ease;
      display: inline-block;
      border: 1px solid #ccc;
      margin: 4px 10px 4px 0;

      span {
        display: block;
        font-size: 12px;
        line-height: 14px;
        color: #a6a6a6;
      }
    }
  }

  .active{
    border-color: #111;
  }
  .dark {
    color: #222;
  }
}
</style>

<template>
  <div>
    <ul class="notification-list">
      <swipe-list
        ref="list"
        :items="user_shelves"
        item-key="id"
      >
        <template class="swipeout-content" v-slot="{ item }">
          <li class="notification profile-notification" v-if="isLoaded">
            <div class="slide-option no-border" >
              <div class="slide-option-slide">
                <div class="slide-option-content pl-4 pr-3">
                  <router-link :to="{name: 'Shelf', params: {id: item.id}}">
                    <div class="shelf-wrapper">
                      <div class="col-6 shelf-image-wrapper">
                          <img
                            :src="item.book_thumb ? coverUrl + item.book_thumb : coverUrl + 'no_cover.png' "
                            :alt="item.type"
                            class="shelf-image"
                          />
                         <!-- <div class="shelf-icon" v-else><i class="fas fa-book"></i></div> -->
                      </div>
                      <div class="col-6">
                        <h3 class="book-side-title">{{ item.name }}</h3>
                        <!-- <p class="book-side-info">4 książki placeholder</p> -->
                        <a href="#" class="book-side-option-icon"  @click.prevent="moreOptions(item.id)">
                          <i class="fas fa-ellipsis-h"></i>
                        </a>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <div v-else><Loader/></div>
          <ModalSlot title="Dodaj książkę" v-if="isPopupShown && (check_id === item.id)" @close-modal="closeModal(false)">
            <div class="text-center mb-2 modal-main-title">Opcje</div>
            <router-link :to="{name: 'EditShelf', params: {id: item.id}}"><button class="btn-black-border w-100 mt-3 mb-1"  v-if="item.type === 'user' || item.type === 'top'">
              <span v-if="item.type === 'top'">Edytuj TOP 5</span>  <span v-else>Edytuj półkę</span>
            </button></router-link>
            <button class="btn-black-border w-100 mt-3 mb-1"  v-if="item.type === 'user' || item.type === 'top'" @click="remove(item)">
              <span v-if="item.type === 'top'">Usuń TOP 5</span>  <span v-else>Usuń półkę</span>
            </button>
            <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://' + plainUrl + '/shelf/' + item.id + '?action=share&type=shelf&user_id=' + my_id" target="_blank"><button class="btn-black-border w-100 mt-3 mb-1">
              <span v-if="item.type === 'top'">Udostępnij TOP 5</span>  <span v-else>Udostępnij półkę</span>
            </button></a>
            <button class="btn-black-border w-100 mt-3 mb-1" @click="openShareModal(item.id)">
              <span v-if="item.type === 'top'">Poleć TOP 5</span>  <span v-else>Poleć półkę</span>
            </button>
            <router-link to="/dodaj"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj książkę</button></router-link>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
          </ModalSlot>
           <ModalSlot title="Dodaj książkę" v-if="isPopupShare && (checkShare_id === item.id)" @close-modal="closeShareModal(false)">
            <div class="text-center mb-4 modal-main-title">
              <span v-if="item.type === 'top'">Poleć TOP 5 znajomym</span>  <span v-else>Poleć półkę znajomym</span>
            </div>
            <div v-if="friendsList.length">
              <div class="filter-wrapper mb-3">
                <input type="text" name="filterFriends" class="input-filter-friends" placeholder="Wyszukaj znajomych" v-model="search"/>
                <div class="ml-3"><i class="fas fa-search"></i></div>
              </div>
              <div class="people-wrapper mt-2">
                <div v-for="(person, key) in filtered" :key="key">
                  <div  class="row  mb-3 d-flex align-items-center" @click="selectPerson(key, person.id)">
                    <div class="col-3">
                      <div :class="{'cover checked-cover': selected.filter(function(item) { return item == person.id }).length > 0 }">
                          <img :src="person.thumb_url ? coverUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')" class="notification-avatar"/>
                      </div>
                    </div>
                    <div class="col-8"><div class="people-wrapper-title">{{person.username}}</div></div>
                </div>
                  </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center"><strong>Nie masz jeszcze znajomych, którym możesz udostępnić półkę</strong></div>
            </div>
            <button class="btn-black-border w-100 mt-3 mb-1" v-if="friendsList.length" @click="recommendShelf">
              <span v-if="item.type === 'top'">Udostępnij TOP 5</span>  <span v-else>Udostępnij półkę</span>
            </button>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="closeShareModal(false)">Zamknij</button>
          </ModalSlot>
          <div class="container">
            <hr class="section-spacer mt-4 mb-0" />
          </div>
        </template>
        <template v-slot:right="{ item }">
          <div @click="remove(item)" class="swipeout-action option-red text-center" v-if="item.type === 'user' || item.type === 'top'">
                  <a href="#">
                    <i class="far fa-trash-alt"></i>
                    <span class="d-block mt-1">
                      Usuń
                    </span>
                  </a>
          </div>
        </template>
        <template v-slot:empty>
          <div>
            Brak półek
          </div>
        </template>
      </swipe-list>
    </ul>
  </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
import ModalSlot from '../components/ModalSlot'
import Loader from './Loader'
export default {
  name: 'LibraryShelf',
  props: {
    props: Object,
    shelves: [Array, Object]
  },
  components: {
    SwipeList,
    ModalSlot,
    Loader
  },
  data () {
    return {
      user_shelves: [],
      index: [],
      selected: [],
      check_id: 0,
      checkShare_id: 0,
      isLoaded: false,
      isPopupShown: false,
      isPopupShare: false,
      friendsList: [],
      search: ''
    }
  },
  created () {
    this.user_shelves = this.shelves

    this.$https('/friends', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(res => {
        this.friendsList = res.data.response
      })
      .catch(error => {
        console.log('', error)
      })
    this.isLoaded = true
  },
  computed: {
    filtered () {
      return this.friendsList.filter(x => {
        return x.username.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    my_id () { return this.$store.state.userData.userId }
  },
  methods: {
    selectPerson (key, personId) {
      let isSelected = false
      let toDelete = 0
      this.selected.forEach(e => {
        if (e === parseInt(personId)) {
          isSelected = true
          toDelete = this.selected.indexOf(e)
        }
      })

      if (isSelected === false) {
        this.selected.push(parseInt(personId))
      } else {
        this.selected.splice(toDelete, 1)
      }
    },
    recommendShelf () {
      this.selected.forEach(e => {
        this.$https('/share/shelf/' + this.check_id + '/' + e, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          this.isPopupShare = false
          this.check_id = 0
        })
          .catch(error => {
            console.log('', error)
          })
      })
    },
    openShareModal (id) {
      this.checkShare_id = id
      this.isPopupShare = true
      this.closeModal(false)
    },
    closeModal (value) {
      this.isPopupShown = false
      this.check_id = 0
    },
    closeShareModal (value) {
      this.isPopupShare = false
    },
    moreOptions (id) {
      this.check_id = id
      this.isPopupShown = true
    },
    remove (item) {
      this.user_shelves = this.user_shelves.filter(i => i !== item)
      const getData = `my_id=${item.user_id}`
      this.$https('/delete/shelf/' + item.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
      })
        .catch(error => {
          console.log('', error)
        })
      this.isPopupShown = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .shelf-wrapper {
      display: flex;
  }
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-filter-friends {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
  .share-shelf {
    &-wrapper {
    width: 90%;
    max-height: 50vh;
    overflow: scroll;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    &-avatar {
      width: 50px;
      height: 50px;
    }
  }
  .book-side-option-icon {
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .people-wrapper {
    max-height: 45vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &-title {
      font-size: 18px;
      margin-left: 5px;
    }
  }

  .notification-avatar {
    width: 55px;
    height: 55px;
    border-radius: 50px;
  }
  .cover {
    max-height: 100%;
    width: 100%;
    display: block;

    &::before {
      display: block;
      width: 55px;
      height: 55px;
      margin-left: 15px;
      border-radius: 50%;
      content: "";
      background-color: #000;
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: 50;
    }
    &::after {
      content: '';
      display: block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #fff;
      z-index: 100;
      content: '\f00c';
      position: absolute;
      top: 40%;
      left: 55%;
    }
    &.checked {
      &-cover {
        &::before {
          display: block;
          content: "";
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
   .shelf-icon {
     font-size: 70px;
     height: 215px;
     width: 150px;
     background-color: #fff;
     color: #000;
     display: flex;
     justify-content: center;
     align-items: center;
   }
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shelf-image {
    width: 150px;
    max-height: 250px;
  }
  @media screen and (max-width: 320px) {
    .shelf-image {
      width: 120px;
      // maxheight: 110px;
    }
  }
  .btn-black-border {
    a {
      color: #000;

      &:focus, &:hover {
        color: #fff;
      }
    &:focus, &:hover {
      a {
        color: #fff;
      }
    }
    }
    // a {
    //   color: #000;
    // }

    // &:active, &:focus {
    //   a {
    //     color: #fff
    //   }
    // }
  }
</style>

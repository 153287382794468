<template>
  <div>
    <div v-if="isLoaded">
        <div class="container">
          <div class="text-center top-heading-medium mt-4">
            Moje TOP 5
          </div>
          <router-link :to="{name: 'LibraryAddShelf', params: {id: 1}}">
            <a href="#" class="btn-black-full w-100 mb-4">Dodaj TOP 5</a>
          </router-link>
        </div>
        <div v-if="topShelf.length">
          <LibraryShelf :shelves="topShelf"/>
        </div>
      <div class="container">
          <div class="text-center top-heading-medium mt-4">
            Moje półki
          </div>
          <router-link :to="{name: 'LibraryAddShelf', params: {id: 0}}">
            <a href="#" class="btn-black-full w-100 mb-4">Dodaj półkę</a>
          </router-link>
        </div>
        <div v-if="restShelf.length">
          <LibraryShelf :shelves="restShelf" />
        </div>
        <!-- <div class="container mt-4">
          <div class="slider-container" v-if="topShelf.length">
          <div class="text-center top-heading-medium mt-4">
            {{topShelf[0].name}}
          </div>
            <div class="growing-cover-slider">
              <UserProfileSlider :props="topShelfBooks" :componentId="compId"/>
            </div>
        <div class="profile-book-name text-center mt-4">
          <div class="book-heading-small">{{bookData.autor}}</div>
          <div class="book-heading-big text-center">
            {{bookData.tytul}}
          </div>
        </div>
        </div>
          <div class="text-center">
            <a href="#" class="book-side-option-icon mt-0">
              <i class="fas fa-ellipsis-h"></i>
            </a>
          </div>
        </div> -->
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
// import UserProfileSlider from './UserProfileSlider'
import LibraryShelf from './LibraryShelf'
import Loader from './Loader'
// import EventBus from '../eventBus'
export default {
  name: 'LibraryBooks',
  components: {
    // UserProfileSlider,
    LibraryShelf,
    Loader
  },
  data () {
    return {
      compdId: 'topBooks',
      activeBookArray: '',
      activeTopArray: '',
      isLoaded: false,
      user_shelves: [],
      topShelf: [],
      restShelf: [],
      topShelfBooks: [],
      bookData: { autor: '', tytul: '' }
    }
  },
  async created () {
    await this.$https('/shelf', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.isLoaded = true
        const tempShelfArray = data.response
        for (const element of tempShelfArray) {
          if (element.type === 'top') {
            this.topShelf.push(element)
          } else {
            this.restShelf.push(element)
          }
        }
      })
      .catch(error => {
        console.log('', error)
      })
  }
}
</script>
<style lang="scss" scoped>

</style>

<template>
    <div>
      <TabsNotification :activeTab="activeTab" :tabs="tabs">
        <template slot="tab-head-books">
          książki
        </template>
        <template slot="tab-panel-books">
          <div class="app-content-box bg-gray">
            <LibraryBooks />
          </div>
        </template>

        <template slot="tab-head-people">
          osoby
        </template>
        <template slot="tab-panel-people">
            <LibraryPeople />
        </template>

        <template slot="tab-head-genres">
          kategorie
        </template>
        <template slot="tab-panel-genres">
            <LibraryUpdateGenres />
        </template>
      </TabsNotification>
    </div>
</template>
<script>
import TabsNotification from '../components/TabsNotification'
import LibraryBooks from '../components/LibraryBooks'
import LibraryPeople from '../components/LibraryPeople'
import LibraryUpdateGenres from '../components/LibraryUpdateGenres'
export default {
  name: 'Library',
  components: {
    TabsNotification,
    LibraryBooks,
    LibraryPeople,
    LibraryUpdateGenres
  },
  data () {
    return {
      activeTab: 'books',
      tabs: ['books', 'people', 'genres']
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
    /*padding-bottom: calc(60px + env(safe-area-inset-bottom));*/
    padding-bottom: 80px;
  }
</style>

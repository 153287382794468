<template>
  <div>
    <div class="wrapper-people" v-if="!isFriendsLoading && !isProposedLoading">
      <div class="container" v-if="friendsList.length">
        <!-- <hr class="section-spacer section-spacer-gray"> -->
        <div class="text-center top-heading-medium mt-5">Twoi znajomi</div>
          <div class="avatar-grid">
            <div v-for="(person, index) in friendsList" :key="index" class="item">
              <router-link :to="{name: 'OtherUserProfile', params: {id: person.id}}" class="text-dark">
                <img
                  :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                  class="people-avatar"
                  :alt="person.username"
                />
                      <span class="remove-button-small" @click.prevent="openModal(person.id)"></span>
                      <div class="top-heading-small avatar-label mt-3">{{person.username}}</div>
              </router-link>
              <ModalSlot title="Usuń osobę" v-if="isPopupShown && (check_id === person.id)" @close-modal="closeModal(false)">
                <div class="text-center mb-4 modal-main-title">Czy chcesz usunąć {{person.username}} z listy znajomych?</div>
                <div class="d-flex justify-content-center align-items-center">
                <img
                  :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')"
                  class="people-avatar"
                  :alt="person.username"
                />
                </div>
                <button class="btn-black-border w-100 mt-3 mb-1" @click="removeFriend(person.id)">Usuń</button>
                <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
              </ModalSlot>
              <ModalSlot title="Usuń osobę" v-if="isRemovedPerson && (check_id === person.id)" @close-modal="closeModal(false)">
                <div class="text-center mb-4 modal-main-title">Usuwanie użytkownika {{person.username}} z listy znajomych</div>
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
                </div>
              </ModalSlot>
            </div>
          </div>
      </div>
      <div class="container">
          <div class="text-center top-heading-medium mt-5 mb-5">Proponowane</div>
      </div>
      <div class="slider-container">
        <div class="growing-cover-slider">
          <div class="tab-content">
            <div class="growing-cover-slider tab-pane-slider swiper-container">
              <div>
                <PeopleSlider :people="proposedList" @update="getNewList" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import PeopleSlider from '../components/PeopleSlider'
import ModalSlot from '../components/ModalSlot'
import Loader from '../components/Loader'
export default {
  name: 'LibraryPeople',
  components: {
    Loader,
    PeopleSlider,
    ModalSlot
  },
  async created () {
    await this.getFriends()
    await this.getProposed()
  },
  data () {
    return {
      isFriendsLoading: true,
      isProposedLoading: true,
      isRemovedPerson: false,
      proposedList: [],
      friendsList: [],
      check_id: 0,
      isPopupShown: false
    }
  },
  methods: {
    openModal (id) {
      this.check_id = id
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
      this.check_id = 0
    },
    removeFriend (id) {
      this.isPopupShown = false
      this.isRemovedPerson = true
      const getData = `user=${id}`
      this.$https('/remfriends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.isRemovedPerson = false
        this.getFriends()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    getNewList () {
      this.isFriendsLoading = true
      this.isProposedLoading = true
      this.getFriends()
      this.getProposed()
    },
    getFriends () {
      this.$https('/friends', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          this.friendsList = res.data.response
          this.isFriendsLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    getProposed () {
      this.$https('/proposed', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          this.proposedList = res.data.response[1].items
          this.isProposedLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper-people {
    // margin-top: 100px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  // .wrapper-avatar {
  //   // display: flex;
  //   // width: 100%;
  //   // justify-content: center;
  // }
  .avatar-label {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }
  .avatar-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
     .item {
       display: flex;
       position: relative;
       justify-content: center;
       align-items: center;
       text-align: center;
     }
  }
  .wrapper-center {
  position: relative;
  }
.wrapper-friends {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: pink;
}
.remove-button-small {
  position: absolute;
  bottom: 25%;
  left: 50%;
  z-index: 0;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  transform: translate(-12px, 12px);

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 22px;
    height: 22px;
    font-size: 22px;
    line-height: 22px;
    color: #000;
    content: '\f056';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    transform: translate(-50%, -50%);
  }
}
</style>
